import React, { useState } from 'react';
import { Route, Link } from "react-router-dom";
import ExtHeader from "../../elements/header/ExtHeader";
import Footer from "../../elements/footer/index";
import './gdprexplained.scss';

const GdprExplained = () => {
    return (
        <React.Fragment>
            <ExtHeader />
            <div className="dk-privacyMain">
                <h3 className="title">GDPR EXPLAINED</h3>
                <div className="dk-privacyText-body">
                    <p>GENERAL INFORMATION</p>

                    <p>The General Data Protection Regulation (GDPR) is the toughest privacy and security law in the world. Though it was drafted and passed by the European Union (EU), it imposes obligations onto organizations anywhere, so long as they target or collect data related to people in the EU. The regulation was put into effect on May 25, 2018. The GDPR will levy harsh fines against those who violate its privacy and security standards, with penalties reaching into the tens of millions of euros.</p>

                    <p>SCOPE & KEY DEFINITONS</p>

                    <p>First, if you process the personal data of EU citizens or residents, or you offer goods or services to such people, then the GDPR applies to you even if you’re not in the EU.</p>

                    <p>The GDPR defines an array of legal terms at length. Below are some of the most important ones that we refer to in this article:</p>

                    <p><b>Personal data</b> — Personal data is any information that relates to an individual who can be directly or indirectly identified. Names and email addresses are obviously personal data. Location information, ethnicity, gender, biometric data, religious beliefs, web cookies, and political opinions can also be personal data. Pseudonymous data can also fall under the definition if it’s relatively easy to ID someone from it.</p>

                    <p><b>Data processing</b> — Any action performed on data, whether automated or manual. The examples cited in the text include collecting, recording, organizing, structuring, storing, using, erasing… so basically anything.</p>

                    <p><b>Data subject</b> — The person whose data is processed. These are your customers or site visitors.</p>

                    <p><b>Data controller</b> — The person who decides why and how personal data will be processed. If you’re an owner or employee in your organization who handles data, this is you.</p>

                    <p><b>Data processor</b> — A third party that processes personal data on behalf of a data controller. The GDPR has special rules for these individuals and organizations. They could include cloud servers like Tresoit or email service providers like ProtonMail.</p>

                    <p className="mt-5">DATA PROTECTION PRINCIPLES</p>

                    <ol>
                        <li>Lawfulness, fairness and transparency — Processing must be lawful, fair, and transparent to the data subject.</li>
                        <li>Purpose limitation — You must process data for the legitimate purposes specified explicitly to the data subject when you collected it.</li>
                        <li>Data minimization — You should collect and process only as much data as absolutely necessary for the purposes specified.</li>
                        <li>Accuracy — You must keep personal data accurate and up to date.</li>
                        <li>Storage limitation — You may only store personally identifying data for as long as necessary for the specified purpose.</li>
                        <li>Integrity and confidentiality — Processing must be done in such a way as to ensure appropriate security, integrity, and confidentiality (e.g. by using encryption).</li>
                        <li>Accountability — The data controller is responsible for being able to demonstrate GDPR compliance with all of these principles.</li>
                    </ol>

                    <p>GDPR.eu is provided to you as helpful resource to quickly find all 99 Articles and 173 Recitals of the Regulation, as well as helpful guides and checklists that walk you through how the Regulation may apply to you. Here you can find the official <a href="http://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32016R0679" target="_blank">PDF</a> of the Regulation (EU) 2016/679 (General Data Protection Regulation) in the current version.</p>

                </div>
            </div>
            <Footer />
        </React.Fragment>
    );
}

export default GdprExplained;
