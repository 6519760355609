import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from "redux";
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import alertMessagesReducer from '../slice/utils/message/messageSlice';
import authReducer from "../slice/auth/authSlice";

const reducers = combineReducers({
    alertMessage: alertMessagesReducer,
    auth: authReducer,
});

export const store = configureStore({
    reducer: reducers,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
});