import React, { Component } from "react";
import Slider from "react-slick";
import "../../../../node_modules/slick-carousel/slick/slick.css";
import "../../../../node_modules/slick-carousel/slick/slick-theme.css";
import TestimonialImg from "../../../assets/images/testimonial.jpg";

export default class TestimonialSlider extends Component {
    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 1100,
            autoplay: true,
            autoplaySpeed: 5000,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                  }
                },
                {
                  breakpoint: 560,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true
                  }
                }
              ]
        };
        return (
            <div>
                <Slider {...settings}>
                    <div>
                        <div className="col-md-12">
                            <div className="dk-testimonialCols">
                                <div className="dk-testimonialtext">
                                    It is a long established fact that a reader wil be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that is has a more-or-less normal.
                                </div>
                                <div className="dk-testimonialImg">
                                    <div className="d-flex align-items-center">
                                        <div className="dk-testiImg">
                                            <img src={TestimonialImg} className="img-fluid img-circle" alt="" />
                                        </div>
                                        <div className="dk-testiText">
                                            <div className="testiTitle">JAMES SMITH</div>
                                            <small>India</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="col-md-12">
                            <div className="dk-testimonialCols">
                                <div className="dk-testimonialtext">
                                    It is a long established fact that a reader wil be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that is has a more-or-less normal.
                                </div>
                                <div className="dk-testimonialImg">
                                    <div className="d-flex align-items-center">
                                        <div className="dk-testiImg">
                                            <img src={TestimonialImg} className="img-fluid img-circle" alt="" />
                                        </div>
                                        <div className="dk-testiText">
                                            <div className="testiTitle">JAMES SMITH</div>
                                            <small>India</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="col-md-12">
                            <div className="dk-testimonialCols">
                                <div className="dk-testimonialtext">
                                    It is a long established fact that a reader wil be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that is has a more-or-less normal.
                                </div>
                                <div className="dk-testimonialImg">
                                    <div className="d-flex align-items-center">
                                        <div className="dk-testiImg">
                                            <img src={TestimonialImg} className="img-fluid img-circle" alt="" />
                                        </div>
                                        <div className="dk-testiText">
                                            <div className="testiTitle">JAMES SMITH</div>
                                            <small>India</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="col-md-12">
                            <div className="dk-testimonialCols">
                                <div className="dk-testimonialtext">
                                    It is a long established fact that a reader wil be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that is has a more-or-less normal.
                                </div>
                                <div className="dk-testimonialImg">
                                    <div className="d-flex align-items-center">
                                        <div className="dk-testiImg">
                                            <img src={TestimonialImg} className="img-fluid img-circle" alt="" />
                                        </div>
                                        <div className="dk-testiText">
                                            <div className="testiTitle">JAMES SMITH</div>
                                            <small>India</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="col-md-12">
                            <div className="dk-testimonialCols">
                                <div className="dk-testimonialtext">
                                    It is a long established fact that a reader wil be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that is has a more-or-less normal.
                                </div>
                                <div className="dk-testimonialImg">
                                    <div className="d-flex align-items-center">
                                        <div className="dk-testiImg">
                                            <img src={TestimonialImg} className="img-fluid img-circle" alt="" />
                                        </div>
                                        <div className="dk-testiText">
                                            <div className="testiTitle">JAMES SMITH</div>
                                            <small>India</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="col-md-12">
                            <div className="dk-testimonialCols">
                                <div className="dk-testimonialtext">
                                    It is a long established fact that a reader wil be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that is has a more-or-less normal.
                                </div>
                                <div className="dk-testimonialImg">
                                    <div className="d-flex align-items-center">
                                        <div className="dk-testiImg">
                                            <img src={TestimonialImg} className="img-fluid img-circle" alt="" />
                                        </div>
                                        <div className="dk-testiText">
                                            <div className="testiTitle">JAMES SMITH</div>
                                            <small>India</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="col-md-12">
                            <div className="dk-testimonialCols">
                                <div className="dk-testimonialtext">
                                    It is a long established fact that a reader wil be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that is has a more-or-less normal.
                                </div>
                                <div className="dk-testimonialImg">
                                    <div className="d-flex align-items-center">
                                        <div className="dk-testiImg">
                                            <img src={TestimonialImg} className="img-fluid img-circle" alt="" />
                                        </div>
                                        <div className="dk-testiText">
                                            <div className="testiTitle">JAMES SMITH</div>
                                            <small>India</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="col-md-12">
                            <div className="dk-testimonialCols">
                                <div className="dk-testimonialtext">
                                    It is a long established fact that a reader wil be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that is has a more-or-less normal.
                                </div>
                                <div className="dk-testimonialImg">
                                    <div className="d-flex align-items-center">
                                        <div className="dk-testiImg">
                                            <img src={TestimonialImg} className="img-fluid img-circle" alt="" />
                                        </div>
                                        <div className="dk-testiText">
                                            <div className="testiTitle">JAMES SMITH</div>
                                            <small>India</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="col-md-12">
                            <div className="dk-testimonialCols">
                                <div className="dk-testimonialtext">
                                    It is a long established fact that a reader wil be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that is has a more-or-less normal.
                                </div>
                                <div className="dk-testimonialImg">
                                    <div className="d-flex align-items-center">
                                        <div className="dk-testiImg">
                                            <img src={TestimonialImg} className="img-fluid img-circle" alt="" />
                                        </div>
                                        <div className="dk-testiText">
                                            <div className="testiTitle">JAMES SMITH</div>
                                            <small>India</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
        );
    }
}