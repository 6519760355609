import React, { useState, useEffect, useRef } from 'react';
import { useHistory, Link } from "react-router-dom";
import './style.scss';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ExtHeader from "../../elements/header/ExtHeader";
import Submit from "../../ui/submitButton/noIconSubmit";
import { useDispatch, useSelector } from "react-redux";
import { showSuccess, showError, clearMessage } from "../../../slice/utils/message/messageSlice";
import { loginUser, clearState } from "../../../slice/auth/authSlice";
import { useCookies } from 'react-cookie';

const Login = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { userInfo, isSuccess, isError, errorMessage } = useSelector(state => state.auth)

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const [cookies, setCookie] = useCookies(['user']);
    const [email, setEmail] = useState(cookies.UserEmail);
    const [password, setPassword] = useState('');

    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleChange = e => {
        const { name, value } = e.target
        switch (name) {
            case "email":
                setEmail(value)
                break;

            case "password":
                setPassword(value)
                break;

            default:
                break;
        }
    }

    const handleSubmit = async () => {
        if (!email || !password) {
            dispatch(showError({ msg: "Please fill all required details" }))
        } else {
            setSuccess(false);
            setLoading(true);
            await dispatch(loginUser({ companyId: cookies.companyId, email, password }))
        }
    };

    useEffect(() => {
        document.title = "Login | DK Global CRM"
        checkCookie()
        dispatch(clearMessage());
        dispatch(clearState());
    }, [])

    const checkCookie = () => {
        if (cookies.Domain == '' || cookies.Domain == undefined) {
            history.push('/login')
        }
    }

    useEffect(() => {
        if (isSuccess) {
            dispatch(clearState())
            setSuccess(true);
            setLoading(false);
            setCookie('UserEmail', email, { path: '/' });
            if (process.env.REACT_APP_ENV == 'dev') {
                window.location.assign(process.env.REACT_APP_REDIRECT_URL + userInfo.token);
            } else {
                window.location.assign("https://" + cookies.Domain + process.env.REACT_APP_REDIRECT_URL + userInfo.token);
            }
        }
        if (isError) {
            dispatch(clearState())
            dispatch(showError({ msg: errorMessage }))
            setSuccess(false);
            setLoading(false);
        }
    }, [isSuccess, isError])

    return (
        <React.Fragment>
            <ExtHeader />
            <div className="dk-loginMain">
                <div className="dk-loginContainer">
                    <div className="dk-topTitle">
                        <h3 className="title">Enter User Logins</h3>
                        <h4>For : DK Global Recruitment</h4>
                    </div>

                    <form action="" method="post" className="mx-100">
                        <div className="form-group dk-loginTheree">
                            <div className="d-flex align-items-center">
                                <label htmlFor="">
                                    <input type="text" className="form-control" name="email" onChange={handleChange}
                                        value={email} placeholder="User Email" autoFocus="true" />
                                </label>
                            </div>
                            <div className="d-flex align-items-center">
                                <label htmlFor="">
                                    <input type={showPassword ? "text" : "password"} className="form-control" name="password" onChange={handleChange}
                                        value={password} placeholder="Password" />
                                    <span className="dk-eyeICon domainAppendSpan" onClick={handleClickShowPassword} style={{ backgroundColor: '#e1e1e4' }}>
                                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                    </span>
                                </label>
                            </div>
                            <Submit txt="Login" loading={loading} success={success} onClick={handleSubmit} position="justify-content-center" className="dk-submitBtn mt-3" />
                            <Link to="#" className="dk-forgot d-block text-center mt-3">Forgot login?</Link>
                        </div>
                    </form>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Login;
