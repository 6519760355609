import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const accountEmail = createAsyncThunk(
    'auth/account_email',
    async ({ email }, thunkAPI) => {
        try {
            const response = await fetch('/api/login/check-email',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const accountDomain = createAsyncThunk(
    'auth/account_domain',
    async ({ email, domain }, thunkAPI) => {
        try {
            const response = await fetch('/api/login/check-email-domain',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email,
                        domain
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const loginUser = createAsyncThunk(
    'auth/login',
    async ({ companyId, email, password }, thunkAPI) => {
        try {
            const response = await fetch('/api/login/user/login',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        companyId,
                        email,
                        password,
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                localStorage.setItem(
                    "authentication",
                    JSON.stringify({
                        token: data.token,
                        refreshToken: data.refreshToken
                    })
                );
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        userInfo: {
            accountEmail: '',
            companyName: '',
            accountDomain: '',
            companyId: '',
            token: ''
        },
        isSuccess: false,
        isError: false,
        isLoading: false,
        errorMessage: '',
    },
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isLoading = false;
            state.errorMessage = '';
            return state;
        }
    },
    extraReducers: {
        [accountEmail.pending]: (state) => {
            state.isLoading = true;
        },
        [accountEmail.fulfilled]: (state, { payload }) => {
            state.userInfo.accountEmail = payload.accountEmail
            state.userInfo.companyName = payload.companyName;
            state.isSuccess = true;
            state.isLoading = false;
        },
        [accountEmail.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
            state.isLoading = false;
        },
        [accountDomain.pending]: (state) => {
            state.isLoading = true;
        },
        [accountDomain.fulfilled]: (state, { payload }) => {
            state.userInfo.companyId = payload.companyId;
            state.userInfo.accountDomain = payload.accountDomain;
            state.isSuccess = true;
            state.isLoading = false;
        },
        [accountDomain.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
            state.isLoading = false;
        },
        [loginUser.pending]: (state) => {
            state.isLoading = true;
        },
        [loginUser.fulfilled]: (state, { payload }) => {
            state.userInfo.accountEmail = payload.user.companyId.email;
            state.userInfo.accountDomain = payload.user.companyId.domain;
            state.userInfo.companyName = payload.user.companyId.name;
            state.userInfo.companyId = payload.user.companyId._id;
            state.userInfo.token = payload.token;
            state.isSuccess = true;
            state.isLoading = false;
        },
        [loginUser.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
            state.isLoading = false;
        }
    }
});

export const { clearState } = authSlice.actions;
export default authSlice.reducer;