import React, { useState } from 'react';
import { Route, Link } from "react-router-dom";
import ExtHeader from "../../elements/header/ExtHeader";
import Footer from "../../elements/footer/index";
import './cookiespolicy.scss';

const CookiesPolicy = () => {
    return (
        <React.Fragment>
            <ExtHeader />
            <div className="dk-privacyMain">
                <h3 className="title">Cookies Policy</h3>
                <div className="dk-privacyText-body">
                    <p>INTRODUCTION</p>
                    <p>This policy governs the use by DK Global Recruitment Limited (‘DK Global Recruiters Limited ‘us’ ‘we’) of any data you share with us whether by using our website  www.dkglobalrecruiters.com, submitting a CV to us or otherwise engaging with our services via other channels. DK Global Recruiters Limited respects your privacy and is committed to protecting your personal data. This privacy notice will inform you as to how we use your personal data when you visit the DK Global Recruiters Limited Websites (regardless of where you visit it from) or otherwise share data with us and tell you about your privacy rights and how the law protects you.</p>
                    <p>For the purpose of this document, any reference to ‘our website’ or ‘this website’ or ‘the website’ shall be deemed to be a reference to the DK Global Recruiters Limited Website accessed by you.</p>

                    <p>1 - PURPOSE OF THIS PRIVACY NOTICE</p>
                    <p><b>1. Purpose of this privacy notice:</b> This privacy notice aims to give you information on how DK Global Recruiters Limited collects and processes your personal data through your use of this website, including any data you may provide through this website when you sign up to a newsletter, purchase a product or service or apply to participate on any courses or programs provided by us.</p>
                    <p>It is important that you read this privacy notice together with any other privacy notice or fair processing notice we may provide on specific occasions when we are collecting or processing personal data about you so that you are fully aware of how and why we are using your data. This privacy notice supplements the other notices and is not intended to override them.</p>
                    <p><b>2. Controller:</b> DK Global Recruiters Limited with company registered in Ireland with company number 590583 and with registered address at 58, Gloucester Square, Railway Street, Dublin 1, Ireland. is the controller responsible for your personal data? We have appointed a Privacy Manager who is responsible for overseeing questions in relation to this privacy notice. If you have any questions about this privacy notice, including any requests to exercise your legal rights, please contact the data privacy manager using the details set out below.</p>
                    <p>Name: Deepak Kumar</p>
                    <p>Address: 58, Gloucester Square, Railway Street, Dublin 1, Ireland.</p>
                    <p>Email: <a href="mailto:privacy@dkglobalrecruiters.com">privacy@dkglobalrecruiters.com</a></p>

                </div>
            </div>
            <Footer />
        </React.Fragment>
    );
}

export default CookiesPolicy;
