import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from "react-router-dom";
import './style.scss';
import ExtHeader from "../../elements/header/ExtHeader";
import Submit from "../../ui/submitButton";
import { useDispatch, useSelector } from "react-redux";
import { showError } from "../../../slice/utils/message/messageSlice";
import { accountDomain, clearState } from "../../../slice/auth/authSlice";
import { useCookies } from 'react-cookie';
import moment from 'moment';

const Domain = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const inputRef = useRef();

    const { userInfo } = useSelector(state => state.auth)
    const { isSuccess, isError, errorMessage } = useSelector(state => state.auth)

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const [cookies, setCookie] = useCookies(['user']);
    const [domain, setDomain] = useState(cookies.Domain)

    const handleChange = e => {
        const { name, value } = e.target
        switch (name) {
            case "domain":
                setDomain(value)
                break;

            default:
                break;
        }
    }

    const handleSubmit = async () => {
        if (!domain) {
            dispatch(showError({ msg: 'Please enter account web address.' }))
        } else {
            setSuccess(false);
            setLoading(true);
            await dispatch(accountDomain({ email: cookies.Email, domain }))
        }
    }

    useEffect(() => {
        document.title = "Login | DK Global CRM"
        dispatch(clearState())
        // checkCookie()
    }, [])

    const checkCookie = () => {
        if (cookies.Email == '' || cookies.Email == undefined) {
            history.push('/login')
        } else {
            history.push('/login/domain')
        }
    }

    useEffect(() => {
        inputRef.current.focus();
    })

    useEffect(() => {
        if (isSuccess) {
            dispatch(clearState())
            setSuccess(true);
            setLoading(false);
            setCookie('Domain', domain, { path: '/', expires: moment().add(30, "days").toDate(), sameSite: true });
            setCookie('companyId', userInfo.companyId, { path: '/', expires: moment().add(30, "days").toDate(), sameSite: true });
            history.push("/login/account")
        }
        if (isError) {
            dispatch(showError({ msg: errorMessage }))
            dispatch(clearState())
            setSuccess(false);
            setLoading(false);
        }
    }, [isSuccess, isError])

    return (
        <React.Fragment>
            <ExtHeader />
            <div className="dk-loginMain">
                <div className="dk-loginContainer">
                    <form action="">
                        <h3 className="title mb-4">Enter Account Web Address</h3>
                        <div className="form-group">
                            <label htmlFor="" className="loginNext mb-0">
                                <input type="text" placeholder="e.g. mybusinessname" name="domain" className="form-control" value={domain} onChange={handleChange} style={{ backgroundColor: '#fff' }} autofocus="true" ref={inputRef} />
                                <span className="domainAppendSpan">.dkglobalerp.com</span>
                            </label>
                            <Submit txt="Next" loading={loading} success={success} onClick={handleSubmit} position="justify-content-center" className="dk-submitBtn mt-4" />
                            <Link to="#" className="dk-forgot d-block text-center mt-3">Forgot Web Address?</Link>
                        </div>
                    </form>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Domain;
