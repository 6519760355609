import React, { useState } from 'react';
import { Route, Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';

const BusinessDetailModal = () => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <React.Fragment>
            <span className="dk-infoModal" onClick={handleShow}><i class="fas fa-info-circle"></i></span>

            <Modal className="dk-infoModalMain" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Business Detail Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolores, aspernatur id neque voluptas necessitatibus modi velit nulla deleniti earum vitae voluptatem qui odit cupiditate porro soluta nemo quis minima facilis?</p>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint enim hic quae sequi rem sed atque veniam totam exercitationem asperiores dolore, itaque deleniti architecto ducimus mollitia fugiat est excepturi quis.</p>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default BusinessDetailModal;
