import React, { useState, useEffect, useRef } from 'react';
import { useHistory, Link } from "react-router-dom";
import './style.scss';
import ExtHeader from "../../elements/header/ExtHeader";
import Submit from "../../ui/submitButton";
import { useDispatch, useSelector } from "react-redux";
import { showError, clearMessage } from "../../../slice/utils/message/messageSlice";
import { accountEmail, clearState } from "../../../slice/auth/authSlice";
import { useCookies } from 'react-cookie';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import moment from 'moment';

const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
        This is going to be your common business email address to identify your account,. All user must fill this email before login.
    </Tooltip>
);
const AccountEmail = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const inputRef = useRef();

    const { userInfo, isSuccess, isError, errorMessage } = useSelector(state => state.auth)

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const [cookies, setCookie] = useCookies(['user']);
    const [email, setEmail] = useState(cookies.Email)

    const handleChange = e => {
        const { name, value } = e.target
        switch (name) {
            case "email":
                setEmail(value)
                break;

            default:
                break;
        }
    }

    const handleSubmit = async () => {
        if (!email) {
            dispatch(showError({ msg: 'Please enter account email.' }))
        } else {
            setSuccess(false);
            setLoading(true);
            await dispatch(accountEmail({ email }))
        }
    }

    useEffect(() => {
        document.title = "Login | DK Global CRM"
        dispatch(clearState())
        dispatch(clearMessage())
    }, [])

    useEffect(() => {
        inputRef.current.focus();
    })

    useEffect(() => {
        if (isSuccess) {
            dispatch(clearState())
            setSuccess(true);
            setLoading(false);
            setCookie('Email', email, { path: '/', expires: moment().add(30, "days").toDate(), sameSite: true });
            history.push("/login/domain");
        }
        if (isError) {
            dispatch(showError({ msg: errorMessage }))
            dispatch(clearState())
            setSuccess(false);
            setLoading(false);
        }
    }, [isSuccess, isError])

    return (
        <React.Fragment>
            <ExtHeader />
            <div className="dk-loginMain">
                <div className="dk-loginContainer">
                    <h3 className="title mb-4">Account Email Address</h3>
                    <form action="">
                        <div className="form-group">
                            <label htmlFor="">
                                <input type="text" placeholder="Enter your email address" name="email" className="form-control col-md-10 m-auto" value={email} onChange={handleChange} autofocus="true" ref={inputRef} />
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltip}
                                >
                                    <span className="dk-erploginInfo"><i class="fas fa-info"></i></span>
                                </OverlayTrigger>
                            </label>
                            <Submit txt="Next" loading={loading} success={success} onClick={handleSubmit} position="justify-content-center" className="dk-submitBtn mt-4" />
                        </div>
                    </form>

                    <div className="dk-formLoginFoot">
                        <div className="dk-signupText pl-0">
                            <Link to="#">Forget account email?</Link>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default AccountEmail;
