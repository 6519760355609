import React, { useState, useEffect, useRef } from 'react';
import { useHistory, Link } from "react-router-dom";
import './style.scss';
import ExtHeader from "../../elements/header/ExtHeader";
import Submit from "../../ui/submitButton";
import { useDispatch, useSelector } from "react-redux";
import { showError, clearMessage } from "../../../slice/utils/message/messageSlice";
import { accountEmail, clearState } from "../../../slice/auth/authSlice";
import { useCookies } from 'react-cookie';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';



const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
        This is going to be your common business email address to identify your account,. All user must fill this email before login.
    </Tooltip>
);
const domainTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
        This will be a dedicated url for your business only. It will be like your own private software in cloud
    </Tooltip>
);

const AccountEmail = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const inputRef = useRef();

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        document.title = "Signup | DK Global CRM"
        dispatch(clearState())
        dispatch(clearMessage())
    }, [])

    useEffect(() => {
        //inputRef.current.focus();
    })

    const handleSubmit = async () => {
        history.push("/signup/sign-steptwo");
    }

    const UseFocus = () => {
        const htmlElRef = useRef(null);
        const setFocus = () => {
            htmlElRef.current && htmlElRef.current.focus();
        };
        return [htmlElRef, setFocus];
    };

    const [input1Ref, setInput1Focus] = UseFocus();
    useEffect(() => {
        document.title = "Login | DK Global CRM"
        setInput1Focus();
    }, [setInput1Focus])

    return (
        <React.Fragment>
            <ExtHeader />
            <div className="dk-signupMain">
                <div className="d-signUpFlex">
                    <h3 className="title text-center mb-1">WELCOME TO DK GLOBAL ERP</h3>
                    <h4 className="text-center">Let's get started with three simple signup steps</h4>
                    <div className="dk-signupContainer">
                        <form action="">
                            <div className="form-group mb-4">
                                <label htmlFor="" className="loginNext mb-0">Account Email</label>
                                <label htmlFor="" className="dk-infoDesc">
                                    <input type="text" placeholder="Common Account Email" onFocus={(e) => e.target.placeholder = ''}
                                        onBlur={(e) => e.target.placeholder = 'Common Account Email'} name="email" className="form-control m-auto" onClick={() => { setInput1Focus() }} autofocus="true" ref={inputRef} />
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                    >
                                        <span><i class="fas fa-info"></i></span>
                                    </OverlayTrigger>
                                </label>
                            </div>
                            <div className="form-group">
                                <label htmlFor="" className="loginNext mb-0">Account Domain</label>
                                <div className="d-flex align-items-center">
                                    <input type="text" placeholder="e.g. mybusinessname" onFocus={(e) => e.target.placeholder = ''}
                                        onBlur={(e) => e.target.placeholder = 'e.g. mybusinessname'} name="domain" className="form-control right-no-radius" onClick={() => { setInput1Focus() }} style={{ backgroundColor: '#fff' }} autofocus="true" ref={inputRef} />
                                    <span className="domainAppendSpan dk-stepOneDomain">
                                        .dkglobalerp.com
                                        <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={domainTooltip}
                                        >
                                            <i class="fas fa-info"></i>
                                        </OverlayTrigger>
                                    </span>
                                </div>
                            </div>
                            <Submit txt="Next" loading={loading} success={success} onClick={handleSubmit} position="justify-content-center" className="dk-submitBtn mt-3" />

                        </form>

                        {/* <div className="dk-formSignupFoot">
                            <div className="dk-signupText pl-0">
                                <Link to="#">Alreday account.</Link>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
}

export default AccountEmail;
