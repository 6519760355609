import React, { useEffect } from 'react';
import { Route, Link } from "react-router-dom";
import ExtHeader from "../../elements/header/ExtHeader";
import Footer from '../../elements/footer/index';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import FaqContent from './FaqContent';
import './faq.scss';

const FaqPage = () => {
    return (
        <React.Fragment>
            <ExtHeader />
            <div className="dk-faqMain">
                <h3 className="title">VISITORS <b>FAQ</b></h3>

                <div className="dk-faqColsMain">
                    <div className="dk-faqTabs">
                        <Tabs defaultActiveKey="tabs_1" id="uncontrolled-tab-example">
                            <Tab eventKey="tabs_1" title="Company">
                                <FaqContent />
                            </Tab>
                            <Tab eventKey="tabs_2" title="Members">
                                <FaqContent />
                            </Tab>
                            <Tab eventKey="tabs_3" title="Benefits">
                                <FaqContent />
                            </Tab>
                            <Tab eventKey="tabs_4" title="Solutions">
                                <FaqContent />
                            </Tab>
                            <Tab eventKey="tabs_5" title="All Prices">
                                <FaqContent />
                            </Tab>
                            <Tab eventKey="tabs_6" title="Transfer">
                                <FaqContent />
                            </Tab>
                            <Tab eventKey="tabs_7" title="Payments">
                                <FaqContent />
                            </Tab>
                            <Tab eventKey="tabs_8" title="Protections">
                                <FaqContent />
                            </Tab>
                            <Tab eventKey="tabs_9" title="Restrictions">
                                <FaqContent />
                            </Tab>
                            <Tab eventKey="tabs_10" title="Joining Process">
                                <FaqContent />
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    )
}

export default FaqPage;
