import React, { useState } from 'react';
import { Route, Link } from "react-router-dom";
import ExtHeader from "../../elements/header/ExtHeader";
import Footer from "../../elements/footer/index";
import './privacypolicy.scss';

const PrivacyPolicy = () => {
    return (
        <React.Fragment>
            <ExtHeader />
            <div className="dk-privacyMain">
                <h3 className="title">PRIVACY POLICY</h3>
                <div className="dk-privacyText-body">
                    <p>INTRODUCTION</p>
                    <p>This policy governs the use by DK Global Recruitment Limited (‘DK Global Recruiters Limited ‘us’ ‘we’) of any data you share with us whether by using our website  www.dkglobalrecruiters.com, submitting a CV to us or otherwise engaging with our services via other channels. DK Global Recruiters Limited respects your privacy and is committed to protecting your personal data. This privacy notice will inform you as to how we use your personal data when you visit the DK Global Recruiters Limited Websites (regardless of where you visit it from) or otherwise share data with us and tell you about your privacy rights and how the law protects you.</p>
                    <p>For the purpose of this document, any reference to ‘our website’ or ‘this website’ or ‘the website’ shall be deemed to be a reference to the DK Global Recruiters Limited Website accessed by you.</p>

                    <p>1 - PURPOSE OF THIS PRIVACY NOTICE</p>
                    <p><b>1. Purpose of this privacy notice:</b> This privacy notice aims to give you information on how DK Global Recruiters Limited collects and processes your personal data through your use of this website, including any data you may provide through this website when you sign up to a newsletter, purchase a product or service or apply to participate on any courses or programs provided by us.</p>
                    <p>It is important that you read this privacy notice together with any other privacy notice or fair processing notice we may provide on specific occasions when we are collecting or processing personal data about you so that you are fully aware of how and why we are using your data. This privacy notice supplements the other notices and is not intended to override them.</p>
                    <p><b>2. Controller:</b> DK Global Recruiters Limited with company registered in Ireland with company number 590583 and with registered address at 58, Gloucester Square, Railway Street, Dublin 1, Ireland. is the controller responsible for your personal data? We have appointed a Privacy Manager who is responsible for overseeing questions in relation to this privacy notice. If you have any questions about this privacy notice, including any requests to exercise your legal rights, please contact the data privacy manager using the details set out below.</p>
                    <p>Name: Deepak Kumar</p>
                    <p>Address: 58, Gloucester Square, Railway Street, Dublin 1, Ireland.</p>
                    <p>Email: <a href="mailto:privacy@dkglobalrecruiters.com">privacy@dkglobalrecruiters.com</a></p>

                    <p>You have the right to make a complaint at any time to the Data Protection Commissioner’s Office <a href="www.dataprotection.ie" target="_blank">(www.dataprotection.ie)</a> being the supervisory authority for data protection issues. We would, however, appreciate the chance to deal with your concerns before you approach the Data Protection Commissioner’s Office so please contact us in the first instance by emailing us at  <a href="mailto:privacy@dkglobalrecruiters.com">privacy@dkglobalrecruiters.com</a>.</p>

                    <p><b>3. Changes to the privacy notice</b> and your duty to inform us of changes: This version was last updated on 01/Jan/2021, It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us.</p>

                    <p className="mb-5"><b>4. Third-Party Links:</b> The Website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy notice of every website you visit.</p>

                    <p>2 - THE DATA WE COLLECT ABOUT YOU</p>
                    <p><b>1. Personal Data:</b> Personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data). We may collect, use, store and transfer different kinds of personal data about you which we have grouped together as follows:</p>

                    <p>I. Identity Data includes first name, last name, title, date of birth</p>

                    <p>II. Contact Data includes address, email address and telephone numbers.</p>

                    <p>III. Financial Data includes bank account, salary information.</p>

                    <p>IV. Curriculum Vitae Data includes employment history, education and training history</p>

                    <p>V. Recruitment Data includes details about positions for which you have been put forward, interviews attended, interview feedback.</p>

                    <p>VI. Technical Data includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform and other technology on the devices you use to access this website.</p>

                    <p>VII. Profile Data includes your username and password, your interests, preferences, feedback and survey responses.</p>

                    <p>VIII. Usage Data includes information about how you use our website and services.</p>

                    <p>IX. Marketing and Communications Data includes your preferences in receiving marketing from us and our third parties and your communication preferences.</p>

                    <p><b>2. Aggregated Data:</b> We also collect, use and share Aggregated Data such as statistical or demographic data for any purpose. Aggregated Data may be derived from your personal data but is not considered personal data in law as this data does not directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific website feature. However, if we combine or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this privacy notice.</p>

                    <p><b>3. Further information:</b> We may provide you with further specific information about the type of data and purpose of it at the point of data capture.</p>

                    <p>3 - HOW IS YOUR DATA COLLECTED?</p>

                    <p>1. We use different methods to collect data from and about you, including through:</p>

                    <p>I. Direct interactions. You may give us your Identity, Contact and Curriculum Vitae Data by filling in forms or by corresponding with us by post, phone, email or otherwise. This includes personal data you provide when you:</p>

                    <p>II. apply directly to us for placement services;</p>

                    <p>III. create an account or profile on our website;</p>

                    <p>IV. apply for jobs advertised by us via third party sites such as Monster, Irish Jobs;</p>

                    <p>V. give us some feedback.</p>

                    <p>VI. Send your Curriculum Vitae as an attachment to an email.</p>

                    <p>2. Automated technologies or interactions. As you interact with our website, we may automatically collect Technical Data about your equipment, browsing actions and patterns. We collect this personal data by using cookies, and other similar technologies. Please see our [cookie policy] for further details.</p>

                    <p>3. Third parties or publicly available sources. We may receive personal data about you from various third parties as set out below</p>

                    <p>I. LinkedIn or other public forums;</p>

                    <p>II. Former employers in the context of reference checking;</p>

                    <p>III. Potential employers in the context of interview feedback;</p>

                    <p>4 - HOW WE USE YOUR PERSONAL DATA</p>

                    <p>We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:</p>

                    <p>I. Where we need to perform the contract, we are about to enter into or have entered into with you.</p>

                    <p>II. Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.</p>

                    <p>III. Where we need to comply with a legal or regulatory obligation.</p>

                    <p>IV. Paragraph 5 below set out the types of lawful basis that we will rely on to process your personal data.</p>

                    <p>If consent is used as a legal basis for processing your data, you have the right to withdraw consent at any time by sending an email from the account we use to contact you to <a href="mailto:privacy@dkglobalrecruiters.com">privacy@dkglobalrecruiters.com</a> or use the Unsubscribe link on any of our marketing emails.</p>

                    <p>5 - PURPOSES FOR WHICH WE WILL USE YOUR PERSONAL DATA</p>

                    <p>We have set out below, in a table format, a description of all the ways we plan to use your personal data, and which of the legal bases we rely on to do so. We have also identified what our legitimate interests are where appropriate.</p>

                    <p>Note that we may process your personal data for more than one lawful ground depending on the specific purpose for which we are using your data. Please email us on privacy@ dkglobalrecruitment.com if you need details about the specific legal ground we are relying on to process your personal data where more than one ground has been set out in the table below.</p>

                    <p>The lawful basis of processing that we use are as follows:</p>

                    <p>1. Legitimate Interest means the interest of our business in conducting and managing our business to enable us to give you the best service and the best and most secure experience. We make sure we consider and balance any potential impact on you (both positive and negative) and your rights before we process your personal data for our legitimate interests. We do not use your personal data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law). You can obtain further information about how we assess our legitimate interests against any potential impact on you in respect of specific activities by emailing us on privacy@dkglobalrecruiters.com</p>

                    <p>2. Performance of Contract means processing your data where it is necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract.</p>

                    <p>3. Comply with a legal or regulatory obligation means processing your personal data where it is necessary for compliance with a legal or regulatory obligation that we are subject to.</p>

                    <p>4. Consent (“The consent of the data subject” as “any freely given, specific, informed and unambiguous indication of the data subject's wishes by which he or she by statement or by a clear affirmative action, signifies agreement to the processing of personal data relating to him or her.”)</p>

                    <p>Purpose/Activity Type of data Lawful basis for processing including basis of legitimate interest</p>

                    <p>To process your application for an advertised position</p>

                    <p>(a) Identity</p>

                    <p>(b) Contact</p>

                    <p>(c) Curriculum Vitae 1. Consent where you apply directly to a job advertisement posted by us or on our behalf</p>

                    <ol>
                        <li>Legitimate Interest where we obtain your data from a 3rd party pending our obtaining your Consent to be considered for a position.</li>
                        <li>To keep your data on file for ongoing Candidate placement services:</li>
                        <li>(a) Identity</li>
                        <li>(b) Contact</li>
                        <li>(c) Curriculum Vitae</li>
                        <li>(d) Recruitment</li>
                        <li>(e) Financial</li>
                        <li>(f) Technical</li>
                        <li>(g) Marketing and Communications (a) Consent</li>
                        <li>To manage our relationship with you which will include:</li>
                        <li>(a) Notifying you about changes to our terms or privacy policy</li>
                        <li>(b) Asking you to leave a review or take a survey</li>
                        <li>(d) Market Commentary (a) Identity</li>
                        <li>(b) Contact</li>
                        <li>(c) Profile</li>
                        <li>(d) Marketing and Communications (a) Performance of a contract with you</li>
                        <li>(b) Necessary to comply with a legal obligation</li>
                        <li>(c) Necessary for our legitimate interests (to keep our records updated and to study how customers use our services)</li>
                        <li>To enable you to partake in a prize draw, competition or complete a survey (a) Identity</li>
                        <li>(b) Contact</li>
                        <li>(c) Profile</li>
                        <li>(d) Usage</li>
                        <li>(e) Marketing and Communications (a) Consent</li>
                        <li>To administer and protect our business and this website (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data)</li>
                        <li>(a) Identity</li>
                        <li>(b) Contact</li>
                        <li>(c) Technical (a) Necessary for our legitimate interests (for running our business, provision of administration and IT services, network security, to prevent fraud and in the context of a business reorganisation or group restructuring exercise)</li>
                        <li>(b) Necessary to comply with a legal obligation</li>
                        <li>To deliver relevant website content and advertisements to you and measure or understand the effectiveness of the advertising we serve to you</li>
                        <li>(a) Identity</li>
                        <li>(b) Contact</li>
                        <li>(c) Profile</li>
                        <li>(d) Usage</li>
                        <li>(e) Marketing and Communications</li>
                        <li>(f) Technical Subject to our Cookie Policy</li>
                        <li>To use data analytics to improve our website, products/services, marketing, customer relationships and experiences (a) Technical</li>
                        <li>(b) Usage Subject to our Cookie Policy</li>
                        <li>To make suggestions and recommendations to you about goods or services that may be of interest to you (a) Identity</li>
                        <li>(b) Contact</li>
                        <li>(c) Technical</li>
                        <li>(d) Usage</li>
                        <li>(e) Profile Consent</li>
                        <li>Change of purpose</li>
                        <li>We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose, please email us on privacy@dkglobalrecruiters.com If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so, Please note that we may process your personal data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.</li>
                        <li>Opting Out</li>
                        <li>You can ask us to stop sending you marketing messages at any time by clicking on the unsubscribe button on any of these messages or by emailing us on privacy@dkglobalrecruiters.com at any time. Where you opt out of receiving these marketing messages, this will not apply to personal data provided to us as a result of a course participation, membership registration or other transactions.</li>
                    </ol>

                    <p>6 - DISCLOSURES OF YOUR PERSONAL DATA</p>

                    <p>We may have to share your personal data with the parties set out below for the purposes set out in the table in paragraph 4 above.</p>

                    <p>1. Internal Third Parties: Other members of the DK Global Recruitment limited corporate group.</p>

                    <p>2. External Third Parties</p>

                    <p>a. Service providers based inside and outside of EEA who provide IT and system administration services.</p>

                    <p>b. Professional advisers acting as processors or joint controllers including lawyers, bankers, auditors and insurers based in Ireland who provide consultancy, payroll, banking, legal, insurance and accounting services.</p>

                    <p>c. Revenue, governmental agencies, regulators, grant authorities and other authorities acting as processors or joint controllers based in Ireland who require reporting of processing activities in certain circumstances or to whom we are obliged to share information about our organisation and participation in it.</p>

                    <p>d. Third parties to whom we may choose to sell, transfer, or merge parts of our business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this privacy notice.</p>

                    <p>e. To include sending your personal information to clients in order to submit your profile for suitable roles which will only be done with your consent.</p>

                    <p>We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.</p>

                    <p>7 - INTERNATIONAL TRANSFERS</p>

                    <p>In order to provide certain services to you including the website, we may need to transfer personal data to locations outside the jurisdiction in which a visitor is viewing the website (the User Jurisdiction) and process personal data outside the User Jurisdiction. If the User Jurisdiction is within the European Economic Area (the EEA) please note that such transfers and processing of personal data may be in locations outside the EEA. Any data sent or uploaded by users may be accessible in jurisdictions outside the User Jurisdiction (including outside the EEA). The level of data protection offered in such jurisdictions may be less than that offered within the User Jurisdiction or (as the case may be) within the EEA.</p>



                    <p>8 - DATA SECURITY</p>

                    <p>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorized way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.</p>

                    <p>We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.</p>



                    <p>9 - DATA RETENTION - HOW LONG WILL YOU USE MY PERSONAL DATA FOR?</p>

                    <p>We will only retain your personal data for as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.</p>

                    <p>To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorized use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.</p>

                    <p>Details of retention periods for different aspects of your personal data are available in our retention policy which you can request from us by emailing us on   <a href="mailto:privacy@dkglobalrecruiters.com">privacy@dkglobalrecruiters.com</a></p>

                    <p>In some circumstances you can ask us to delete your data: see Request erasure below for further information.</p>

                    <p>In some circumstances we may anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes in which case we may use this information indefinitely without further notice to you.</p>

                    <p>10 - YOUR LEGAL RIGHTS</p>

                    <p>Under certain circumstances, you have rights under data protection laws in relation to your personal data.You may:</p>

                    <p>I. Request access to your personal data.</p>

                    <p>This is commonly known as a “data subject access request”. This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it. Click here for more information on our subject access request policy.</p>

                    <p>II. Request correction of your personal data.</p>

                    <p>This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.</p>

                    <p>III. Request erasure of your personal data.</p>

                    <p>This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.</p>

                    <p>IV. Object to processing of your personal data.</p>

                    <p>You may object where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights.</p>

                    <p>V. Request restriction of processing your personal data.</p>

                    <p>This enables you to ask us to suspend the processing of your personal data in the following scenarios: (a) if you want us to establish the data’s accuracy; (b) where our use of the data is unlawful but you do not want us to erase it; (c) where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims; or (d) you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.</p>

                    <p>VI. Request transfer of your personal data to a third party.</p>

                    <p>We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.</p>

                    <p>VII. Right to withdraw consent.</p>

                    <p>Where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.</p>

                    <p>VIII. If you wish to exercise any of the rights set out above, please email us on <a href="mailto:privacy@dkglobalrecruiters.com">privacy@dkglobalrecruiters.com</a></p>


                </div>
            </div>
            <Footer />
        </React.Fragment>
    );
}

export default PrivacyPolicy;
