import React, { useEffect } from 'react';
import { Route, Link } from "react-router-dom";
import './style.scss';
import Header from "../../elements/header/index";
import Footer from "../../elements/footer/index";
import HomeSlider from "./HomeSlider";
import VideoModal from './VideoModal';
import SalesImg from "../../../assets/images/sales.png";
import InfoImg from "../../../assets/images/info.png";
import ChatImg from "../../../assets/images/announcements.png";
import TaskImg from "../../../assets/images/task.png";
import ProjectsImg from "../../../assets/images/projects.png";
import FilesImg from "../../../assets/images/files.png";
import RecruitementImg from "../../../assets/images/recruitement.png";
import ResourcingImg from "../../../assets/images/resourcing.png";
import AdsImg from "../../../assets/images/ads.png";
import ProjectionsImg from "../../../assets/images/projections.png";
import RestaurantsImg from "../../../assets/images/restaurant.png";
import RetailbusinessImg from "../../../assets/images/retail.png";
import SelfemployedImg from "../../../assets/images/self.png";
import AccountingImg from "../../../assets/images/accounting.png";
import TrainingImg from "../../../assets/images/training.png";
import MarketingImg from "../../../assets/images/marketing.png";
import DanceFitnessImg from "../../../assets/images/dancefitness.png";
import BusinessDevelopmentImg from "../../../assets/images/business-development.png";
import HRImg from "../../../assets/images/hr.png";
import SupportImg from "../../../assets/images/support.png";
import PurchaseImg from "../../../assets/images/purchase.png";
import ReportImg from "../../../assets/images/reports.png";
import FBIcon from "../../../assets/images/fb-icon.png";
import InstaIcon from "../../../assets/images/insta-icon.png";
import LinkedinIcon from "../../../assets/images/linkedin-icon.png";
import YoutubeIcon from "../../../assets/images/youtube-icon.png";
import TwitterIcon from "../../../assets/images/twitter-icon.png";
import PlanImg from "../../../assets/images/plan.png";
import Button from '@material-ui/core/Button';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import TestimonialSlider from './TestimonialSlider';
import DkVideo from "../../../assets/images/marvel.mp4";
import DkVideoImg from "../../../assets/images/video-coverimg.jpeg";

const Home = () => {
    useEffect(() => {
        document.title = "DK Global CRM"
    }, [])

    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: 'rgba(0, 0, 0, 250)',
            color: '#fff',
            fontSize: 12,
            fontWeight: 400,
            letterSpacing: 0.5,
            fontFamily: 'Poppins',
            maxWidth: 200,
            textAlign: 'center',
        },
        arrow: {
            color: 'rgba(0, 0, 0, 250)',
        },
    }))(Tooltip);

    return (
        <React.Fragment>
            <Header />

            <div dark={true} id="section_1">
                <div className="dk-erpBannerMain">
                    <HomeSlider />
                </div>
            </div>

            <div dark={true} id="section_2">
                <div className="dk-erpHomeBlankSec">
                    <div className="title text-center mb-4">What is the Solution ?</div>
                    <div className="dk-videoBox">
                        <video src={DkVideo} width="100%" height="100%" controls loop poster={DkVideoImg}></video>
                        {/* <div className="dk-palyIcon">
                            <i class="fas fa-play"></i>
                        </div> */}
                    </div>
                </div>
            </div>

            <div dark={true} id="section_3">
                <div className="dk-applicationMain">
                    <div className="dk-appColsMain">
                        <div className="title">STANDARD APPLICATIONS
                            <h4 className="text-center col-lg-6 m-auto">Lorem ipsum dolor sit amet consectetur adipisicing elit. At neque numquam minus sint voluptas consequatur earum.</h4>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-2">
                                <LightTooltip title="Training" arrow placement="top">
                                    <div className="dk-portalBox dk_portalBox_1">
                                        <Route>
                                            <Link to="#">
                                                <div>
                                                    <div className="dk-Portalicons">
                                                        <img src={TrainingImg} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dk-PortalTitle">Training</div>
                                                </div>
                                            </Link>
                                        </Route>
                                    </div>
                                </LightTooltip>
                            </div>
                            <div className="col-md-2">
                                <LightTooltip title="Report" arrow placement="top">
                                    <div className="dk-portalBox dk_portalBox_2">
                                        <Route>
                                            <Link to="#">
                                                <div>
                                                    <div className="dk-Portalicons">
                                                        <img src={ReportImg} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dk-PortalTitle">Report</div>
                                                </div>
                                            </Link>
                                        </Route>
                                    </div>
                                </LightTooltip>
                            </div>
                            <div className="col-md-2">
                                <LightTooltip title="Support" arrow placement="top">
                                    <div className="dk-portalBox dk_portalBox_3">
                                        <Route>
                                            <Link to="#">
                                                <div>
                                                    <div className="dk-Portalicons">
                                                        <img src={SupportImg} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dk-PortalTitle">Support</div>
                                                </div>
                                            </Link>
                                        </Route>
                                    </div>
                                </LightTooltip>
                            </div>
                            <div className="col-md-2">
                                <LightTooltip title="Tasks" arrow placement="top">
                                    <div className="dk-portalBox dk_portalBox_4">
                                        <Route>
                                            <Link to="#">
                                                <div>
                                                    <div className="dk-Portalicons">
                                                        <img src={TaskImg} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dk-PortalTitle">Tasks</div>
                                                </div>
                                            </Link>
                                        </Route>
                                    </div>
                                </LightTooltip>
                            </div>
                            <div className="col-md-2">
                                <LightTooltip title="Announcements" arrow placement="top">
                                    <div className="dk-portalBox dk_portalBox_5">
                                        <Route>
                                            <Link to="#">
                                                <div>
                                                    <div className="dk-Portalicons PortaliconsBtn">
                                                        <img src={ChatImg} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dk-PortalTitle">Announcements</div>
                                                </div>
                                            </Link>
                                        </Route>
                                    </div>
                                </LightTooltip>
                            </div>
                            <div className="col-md-2">
                                <LightTooltip title="Projects" arrow placement="top">
                                    <div className="dk-portalBox dk_portalBox_6">
                                        <Route>
                                            <Link to="#">
                                                <div>
                                                    <div className="dk-Portalicons">
                                                        <img src={ProjectsImg} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dk-PortalTitle">Projects</div>
                                                </div>
                                            </Link>
                                        </Route>
                                    </div>
                                </LightTooltip>
                            </div>
                            <div className="col-md-2">
                                <LightTooltip title="New Leads" arrow placement="top">
                                    <div className="dk-portalBox dk_portalBox_7">
                                        <Route>
                                            <Link to="#">
                                                <div>
                                                    <div className="dk-Portalicons">
                                                        <img src={BusinessDevelopmentImg} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dk-PortalTitle">New Leads</div>
                                                </div>
                                            </Link>
                                        </Route>
                                    </div>
                                </LightTooltip>
                            </div>
                            <div className="col-md-2">
                                <LightTooltip title="Marketing" arrow placement="top">
                                    <div className="dk-portalBox dk_portalBox_8">
                                        <Route>
                                            <Link to="#">
                                                <div>
                                                    <div className="dk-Portalicons">
                                                        <img src={MarketingImg} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dk-PortalTitle">Marketing</div>
                                                </div>
                                            </Link>
                                        </Route>
                                    </div>
                                </LightTooltip>
                            </div>
                            <div className="col-md-2">
                                <LightTooltip title="Purchase" arrow placement="top">
                                    <div className="dk-portalBox dk_portalBox_9">
                                        <Route>
                                            <Link to="#">
                                                <div>
                                                    <div className="dk-Portalicons">
                                                        <img src={PurchaseImg} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dk-PortalTitle">Purchase</div>
                                                </div>
                                            </Link>
                                        </Route>
                                    </div>
                                </LightTooltip>
                            </div>
                            <div className="col-md-2">
                                <LightTooltip title="Sales" arrow placement="top">
                                    <div className="dk-portalBox dk_portalBox_10">
                                        <Route>
                                            <Link to="#">
                                                <div>
                                                    <div className="dk-Portalicons">
                                                        <img src={SalesImg} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dk-PortalTitle">Sales</div>
                                                </div>
                                            </Link>
                                        </Route>
                                    </div>
                                </LightTooltip>
                            </div>
                            <div className="col-md-2">
                                <LightTooltip title="My Files" arrow placement="top">
                                    <div className="dk-portalBox dk_portalBox_11">
                                        <Route>
                                            <Link to="#">
                                                <div>
                                                    <div className="dk-Portalicons">
                                                        <img src={FilesImg} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dk-PortalTitle">My Files</div>
                                                </div>
                                            </Link>
                                        </Route>
                                    </div>
                                </LightTooltip>
                            </div>
                            <div className="col-md-2">
                                <LightTooltip title="Resources" arrow placement="top">
                                    <div className="dk-portalBox dk_portalBox_12">
                                        <Route>
                                            <Link to="#">
                                                <div>
                                                    <div className="dk-Portalicons">
                                                        <img src={ResourcingImg} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dk-PortalTitle">Resources</div>
                                                </div>
                                            </Link>
                                        </Route>
                                    </div>
                                </LightTooltip>
                            </div>
                            <div className="col-md-2">
                                <LightTooltip title="HR" arrow placement="top">
                                    <div className="dk-portalBox dk_portalBox_13">
                                        <Route>
                                            <Link to="#">
                                                <div>
                                                    <div className="dk-Portalicons">
                                                        <img src={HRImg} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dk-PortalTitle">HR</div>
                                                </div>
                                            </Link>
                                        </Route>
                                    </div>
                                </LightTooltip>
                            </div>
                            <div className="col-md-2">
                                <LightTooltip title="Finance" arrow placement="top">
                                    <div className="dk-portalBox dk_portalBox_14">
                                        <Route>
                                            <Link to="#">
                                                <div>
                                                    <div className="dk-Portalicons">
                                                        <img src={ProjectionsImg} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dk-PortalTitle">Finance</div>
                                                </div>
                                            </Link>
                                        </Route>
                                    </div>
                                </LightTooltip>
                            </div>
                            <div className="col-md-2">
                                <LightTooltip title="Accounting" arrow placement="top">
                                    <div className="dk-portalBox dk_portalBox_15">
                                        <Route>
                                            <Link to="#">
                                                <div>
                                                    <div className="dk-Portalicons">
                                                        <img src={AccountingImg} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dk-PortalTitle">Accounting</div>
                                                </div>
                                            </Link>
                                        </Route>
                                    </div>
                                </LightTooltip>
                            </div>


                        </div>
                    </div>

                    <div className="dk-appColsMain mt-5">
                        <div className="title">CUSTOMISED APPLICATIONS
                            <h4 className="text-center col-lg-6 m-auto">Lorem ipsum dolor sit amet consectetur adipisicing elit. At neque numquam minus sint voluptas consequatur earum.</h4>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-2">
                                <LightTooltip title="Recruitement" arrow placement="top">
                                    <div className="dk-portalBox dk_portalBox_manaul">
                                        <Route>
                                            <Link to="#">
                                                <div>
                                                    <div className="dk-Portalicons">
                                                        <img src={RecruitementImg} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dk-PortalTitle">Recruitement</div>
                                                </div>
                                            </Link>
                                        </Route>
                                    </div>
                                </LightTooltip>
                            </div>
                            <div className="col-md-2">
                                <LightTooltip title="Restaurants" arrow placement="top">
                                    <div className="dk-portalBox dk_portalBox_manaul">
                                        <Route>
                                            <Link to="#">
                                                <div>
                                                    <div className="dk-Portalicons">
                                                        <img src={RestaurantsImg} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dk-PortalTitle">Restaurants</div>
                                                </div>
                                            </Link>
                                        </Route>
                                    </div>
                                </LightTooltip>
                            </div>
                            <div className="col-md-2">
                                <LightTooltip title="Dance & Fitness" arrow placement="top">
                                    <div className="dk-portalBox dk_portalBox_manaul">
                                        <Route>
                                            <Link to="#">
                                                <div>
                                                    <div className="dk-Portalicons">
                                                        <img src={DanceFitnessImg} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dk-PortalTitle">Dance & Fitness</div>
                                                </div>
                                            </Link>
                                        </Route>
                                    </div>
                                </LightTooltip>
                            </div>
                            <div className="col-md-2">
                                <LightTooltip title="Retail Business" arrow placement="top">
                                    <div className="dk-portalBox dk_portalBox_manaul">
                                        <Route>
                                            <Link to="#">
                                                <div>
                                                    <div className="dk-Portalicons">
                                                        <img src={RetailbusinessImg} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dk-PortalTitle">Retail Business</div>
                                                </div>
                                            </Link>
                                        </Route>
                                    </div>
                                </LightTooltip>
                            </div>
                            <div className="col-md-2">
                                <LightTooltip title="Self Employed" arrow placement="top">
                                    <div className="dk-portalBox dk_portalBox_manaul">
                                        <Route>
                                            <Link to="#">
                                                <div>
                                                    <div className="dk-Portalicons">
                                                        <img src={SelfemployedImg} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dk-PortalTitle">Self Employed</div>
                                                </div>
                                            </Link>
                                        </Route>
                                    </div>
                                </LightTooltip>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

            <div dark={true} id="section_4">
                <div className="dk-pricingMain">
                    <div className="container">
                        <div className="title mb-5">Pricing
                            <h4 class="text-center col-lg-6 m-auto">Lorem ipsum dolor sit amet consectetur adipisicing elit. At neque numquam minus sint voluptas consequatur earum.</h4>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="dk-pricingBox">
                                    <div className="headtitle bg_standard">
                                        <h3>Standard Plan</h3>
                                    </div>
                                    <div className="dk-priceCols">
                                        <div className="dk-priceBoxImg">
                                            <img src={PlanImg} className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-price">€500<span>/ Mo</span></div>

                                        <ul className="dk-priceList">
                                            <li><div>No of Users <span>1</span></div></li>
                                            <li><div>No. of Admins <span>1</span></div></li>
                                            <li><div>Standard Apps <span>15</span></div></li>
                                            <li><div>Custom Apps <span>No</span></div></li>
                                            <li><div>Clients Support <span>Yes</span></div></li>
                                        </ul>
                                        <div className="dk-priceBookBtn">
                                            <Link to="#">Subscribe</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="dk-pricingBox">
                                    <div className="headtitle bg_premium">
                                        <h3>Premium Plan</h3>
                                    </div>
                                    <div className="dk-priceCols">
                                        <div className="dk-priceBoxImg bg_premium">
                                            <img src={PlanImg} className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-price">€1000<span>/ Mo</span></div>

                                        <ul className="dk-priceList">
                                            <li><div>No of Users <span>10</span></div></li>
                                            <li><div>No. of Admins <span>2</span></div></li>
                                            <li><div>Standard Apps <span>15</span></div></li>
                                            <li><div>Custom Apps <span>No</span></div></li>
                                            <li><div>Clients Support <span>Yes</span></div></li>
                                        </ul>
                                        <div className="dk-priceBookBtn">
                                            <Link to="#" className="bg_premium">Subscribe</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="dk-pricingBox">
                                    <div className="headtitle bg_enterprise">
                                        <h3>Enterprise Plan</h3>
                                    </div>
                                    <div className="dk-priceCols">
                                        <div className="dk-priceBoxImg bg_enterprise">
                                            <img src={PlanImg} className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-price dk-enquery">ENQUIRE</div>

                                        <ul className="dk-priceList">
                                            <li>
                                                <div>No of Users
                                                    <LightTooltip title="Lorem Ipsum is simply dummy text of the printing and typesetting industry" arrow placement="top">
                                                        <span>
                                                            <i class="fas fa-info"></i>
                                                        </span>
                                                    </LightTooltip>
                                                </div>
                                            </li>
                                            <li>
                                                <div>No. of Admins
                                                    <LightTooltip title="Lorem Ipsum is simply dummy text of the printing and typesetting industry" arrow placement="top">
                                                        <span>
                                                            <i class="fas fa-info"></i>
                                                        </span>
                                                    </LightTooltip>
                                                </div>
                                            </li>
                                            <li><div>Standard Apps <span>15</span></div></li>
                                            <li><div>Custom Apps <span>Yes</span></div></li>
                                            <li><div>Clients Support <span>Yes</span></div></li>
                                        </ul>
                                        <div className="dk-priceBookBtn">
                                            <Link to="#" className="bg_enterprise">Subscribe</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div dark={true} id="section_5">
                <div className="dk-benifitsMain">
                    <div className="container">
                        <div className="title mb-0">CLIENTS BENEFITS
                            <h4 class="text-center col-lg-6 m-auto">Lorem ipsum dolor sit amet consectetur adipisicing elit. At neque numquam minus sint voluptas consequatur earum.</h4>
                        </div>
                        <div className="dk-clientBox">
                            <div className="d-flex justify-content-between">
                                <div className="dk-clientAroundCols">
                                    <div className="sbtitle">Common Business Problems</div>
                                    <ul>
                                        <li><i class="fas fa-times"></i> Lorem ipsum dolor sit amet consectetur adipisicing elit.</li>
                                        <li><i class="fas fa-times"></i> Lorem ipsum dolor sit amet consectetur adipisicing elit.</li>
                                        <li><i class="fas fa-times"></i> Lorem ipsum dolor sit amet consectetur adipisicing elit.</li>
                                        <li><i class="fas fa-times"></i> Lorem ipsum dolor sit amet consectetur adipisicing elit.</li>
                                        <li><i class="fas fa-times"></i> Lorem ipsum dolor sit amet consectetur adipisicing elit.</li>
                                        <li><i class="fas fa-times"></i> Lorem ipsum dolor sit amet consectetur adipisicing elit.</li>
                                        <li><i class="fas fa-times"></i> Lorem ipsum dolor sit amet consectetur adipisicing elit.</li>

                                    </ul>
                                </div>
                                <div className="dk-clientLine">
                                    <span><i class="fas fa-arrows-alt-h"></i></span>
                                </div>
                                <div className="dk-clientAroundCols">
                                    <div className="sbtitle">Solution with DK Global ERP</div>
                                    <ul>
                                        <li><i class="fas fa-check"></i> Lorem ipsum dolor sit amet consectetur adipisicing elit.</li>
                                        <li><i class="fas fa-check"></i> Lorem ipsum dolor sit amet consectetur adipisicing elit.</li>
                                        <li><i class="fas fa-check"></i> Lorem ipsum dolor sit amet consectetur adipisicing elit.</li>
                                        <li><i class="fas fa-check"></i> Lorem ipsum dolor sit amet consectetur adipisicing elit.</li>
                                        <li><i class="fas fa-check"></i> Lorem ipsum dolor sit amet consectetur adipisicing elit.</li>
                                        <li><i class="fas fa-check"></i> Lorem ipsum dolor sit amet consectetur adipisicing elit.</li>
                                        <li><i class="fas fa-check"></i> Lorem ipsum dolor sit amet consectetur adipisicing elit.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div dark={true} id="section_6">
                <div className="dk-contactMain">
                    <div className="container">
                        <div className="title">Contact Us
                            <h4 class="text-center col-lg-6 m-auto">Lorem ipsum dolor sit amet consectetur adipisicing elit. At neque numquam minus sint voluptas consequatur earum.</h4>
                        </div>
                        <div className="row">
                            <div className="col-md-6 pr-4">
                                <div className="dk-contactText">
                                    <div className="sbtitle">DK GLOBAL ERP LIMITED</div>
                                    <ul>
                                        <li><i class="fas fa-map-marker-alt"></i> Railway Street, Dublin 1, Ireland</li>
                                        <li><i class="fas fa-phone-alt"></i> +353 1551 2444</li>
                                        <li><Link to="mailto:info@dkglobalerp.com"><i class="fas fa-envelope"></i> info@dkglobalerp.com</Link></li>
                                        <li><Link to="#" target="_blank"><i class="fas fa-external-link-alt"></i> www.dkglobalerp.com</Link></li>
                                    </ul>
                                    <div className="dk-contactSocial">
                                        <div className="d-flex">
                                            <a href="#" title="Facebook"><img src={FBIcon} className="img-fluid" alt="" /></a>
                                            <a href="#" title="Instagram"><img src={InstaIcon} className="img-fluid" alt="" /></a>
                                            <a href="#" title="Linkedin"><img src={LinkedinIcon} className="img-fluid" alt="" /></a>
                                            <a href="#" title="Youtube"><img src={YoutubeIcon} className="img-fluid" alt="" /></a>
                                            <a href="#" title="Twitter"><img src={TwitterIcon} className="img-fluid" alt="" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 pl-4">
                                <form action="">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="">Full Name</label>
                                                <label htmlFor="">
                                                    <input type="text" placeholder="Enter Your Name" name="" id="" className="form-control" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="">Email</label>
                                                <label htmlFor="">
                                                    <input type="text" placeholder="Enter Your Email" name="" id="" className="form-control" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="">Mobile No</label>
                                                <label htmlFor="">
                                                    <input type="text" placeholder="Enter Your Mobile No" name="" id="" className="form-control" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="">Address</label>
                                                <label htmlFor="">
                                                    <input type="text" placeholder="Enter Your Address" name="" id="" className="form-control" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="">Message</label>
                                                <label htmlFor="">
                                                    <textarea name="" placeholder="Write Your Message" id="" className="form-control"></textarea>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group mb-0 text-center">
                                                <button className="submitBtn">Submit</button>
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="dk-testimonialMain">
                <div className="col-md-11 m-auto">
                    <div className="title">TESTIMONIALS
                        <h4 class="text-center col-lg-6 m-auto">Lorem ipsum dolor sit amet consectetur adipisicing elit. At neque numquam minus sint voluptas consequatur earum.</h4>
                    </div>

                    <div className="dk-testimonialSliderMain">
                        <TestimonialSlider />
                        {/* <div className="dk-viewAllBtn">
                            <Link to="#">View All Testimonials</Link>
                        </div> */}
                    </div>
                </div>
            </div>

            <Footer />
        </React.Fragment>
    );
}

export default Home;
