import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import './style.scss';
import Logo from "../../../assets/images/logo.png";
import { useSelector } from "react-redux";
import { Link, animateScroll as scroll } from "react-scroll";

const Header = () => {
    const pathname = window.location.pathname;

    const { isAuthenticate, userInfo } = useSelector(state => state.auth)

    const [isActive, setActive] = useState("false");

    const handleToggle = () => {
        setActive(!isActive);
    };

    const scrollToTop = () => {
        scroll.scrollToTop();
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <React.Fragment>
            <header>
                <div className="dk-headerMain">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="dk-logo">
                            <Route>
                                <a href="/">
                                    <img src={Logo} className="img-fluid" alt="" />
                                </a>
                            </Route>
                        </div>
                        <button className="dk-mobileNavBtn" onClick={handleToggle}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>
                        <div className="dk-navMainContainer" className={`dk-navMainContainer ${isActive ? "openMenu" : ""}`}>
                            <div className="dk-homeNav">
                                <ul className="d-flex">
                                    <Route>
                                        <li>
                                            <a href="/#section_1">
                                                Home
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/#section_2">
                                                Solution
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/#section_3">
                                                Applications
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/#section_4">
                                                Pricing
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/#section_5">
                                                Benefits
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/#section_6">
                                                Contact us
                                            </a>
                                        </li>
                                    </Route>
                                </ul>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="dk-menu">
                                    <Route>
                                        <ul>
                                            <li className={`${pathname.match('/signup') ? 'dk-menuBtn dk-signupBtn signup-active' : 'dk-menuBtn dk-signupBtn'}`}><a href="/signup"><i class="fas fa-building"></i> Signup</a></li>
                                            <li className={`${pathname.match('/login') ? 'dk-menuBtn dk-loginBtn active' : 'dk-menuBtn dk-loginBtn'}`}><a href="/login"><i class="fas fa-sign-out-alt"></i> Login</a></li>
                                        </ul>
                                    </Route>

                                </div>
                                <li className="dk-closeBtn"><button onClick={handleToggle}><i class="fas fa-times"></i></button></li>
                            </div>
                        </div>
                    </div>
                </div>
            </header >
        </React.Fragment >
    );
}

export default Header;
