import { createSlice } from '@reduxjs/toolkit';

const messageSlice = createSlice({
    name: "alertMessage",
    initialState: {
        isSuccess: false,
        isError: false,
        message: ""
    },
    reducers: {
        showSuccess: (state, { payload }) => {
            state.isSuccess = true;
            state.isError = false;
            state.message = payload.msg;
        },
        showError: (state, { payload }) => {
            state.isError = true;
            state.isSuccess = false;
            state.message = payload.msg;
        },
        clearMessage: (state) => {
            state.isSuccess = false;
            state.isError = false;
            state.message = "";
        }
    }
});
export const { showSuccess, showError, clearMessage } = messageSlice.actions;

export default messageSlice.reducer;