import React, { useState, useEffect, useRef } from 'react';
import { useHistory, Link } from "react-router-dom";
import './style.scss';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ExtHeader from "../../elements/header/ExtHeader";
import Submit from "../../ui/submitButton";

const CreateExperience = () => {
    return (
        <React.Fragment>
            <ExtHeader />
            <div className="dk-signupMain">
                <div className="d-signUpFlex">
                    <h3 className="title text-center mb-1">Let’s create the best experience for you</h3>
                    <div className="dk-createAccountCols">
                        <h4 className="text-center mb-4">Let us know a bit more so we can help you get started</h4>
                        <form action="">

                            <div className="clearfix"></div>
                            <div className="form-group">
                                <Submit txt="Continue" position="justify-content-center" className="dk-crateExpBtn mt-4" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default CreateExperience;
