import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import BannerImg from "../../../assets/images/Banner-2.jpg";
import BannerImg2 from "../../../assets/images/banner_2.jpg";
import './style.scss';
import "../../../../node_modules/slick-carousel/slick/slick.css";
import "../../../../node_modules/slick-carousel/slick/slick-theme.css";
import SendQueryModal from "./SendQueryModal";


export default class HomeSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    return (
      <div>
        <Slider {...settings}>
          <div>
            <div className="dk-erpSliderCols">
              <img src={BannerImg} className="img-fluid" alt="" />
              <figure>
                <h2 style={{ color: '#000' }}>Manage Your Entire Business With One Application</h2>
                <p style={{ color: '#333', widt: '100%', maxWidth: '500px', fontSize: '15px' }}>DK Global ERP is a business management solution for SME's Standard and customised applications are available.</p>

                <SendQueryModal />
              </figure>
            </div>
          </div>
          {/* <div>
            <div className="dk-erpSliderCols">
              <img src={BannerImg2} className="img-fluid" alt="" />
              <figure>
                <h2>Lorem, ipsum dolor sit amet consectetur.</h2>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Non soluta quaerat deleniti officiis exercitationem dicta in minus.</p>
                <Link to="#">Read More</Link>
              </figure>
            </div>
          </div> */}

        </Slider>
      </div >
    );
  }
}
