import React, { useState, useEffect, useRef } from 'react';
import { useHistory, Link } from "react-router-dom";
import './style.scss';
import ExtHeader from "../../elements/header/ExtHeader";
import Submit from "../../ui/submitButton";
import { useDispatch, useSelector } from "react-redux";
import { showError, clearMessage } from "../../../slice/utils/message/messageSlice";
import { accountEmail, clearState } from "../../../slice/auth/authSlice";
import { Dropdown } from 'semantic-ui-react';
import BusinessDetailModal from './BusinessDetailModal';

const SignupStepTwo = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const inputRef = useRef();

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        document.title = "Signup | DK Global CRM"
        dispatch(clearState())
        dispatch(clearMessage())
    }, [])

    const handleSubmit = async () => {
        history.push("/signup/domain");
    }

    // dropdown

    const industryOption = [
        {
            key: 'Select',
            text: 'Select',
            value: 'Select',
        },
    ]
    const employeOption = [
        {
            key: '0-5',
            text: '0-5',
            value: '0-5',
        },
        {
            key: '5-10',
            text: '5-10',
            value: '5-10',
        },
        {
            key: '10-50',
            text: '10-50',
            value: '10-50',
        },
        {
            key: '50+',
            text: '50+',
            value: '50+',
        },
    ]

    //   end

    return (
        <React.Fragment>
            <ExtHeader />
            <div className="dk-signupMain">
                <div className="d-signUpFlex">
                    <h3 className="title mb-3">BUSINESS DETAILS <BusinessDetailModal /></h3>
                    <div className="dk-signupContainer">
                        <form action="">
                            <div className="form-group">
                                <label htmlFor="">Name of Business</label>
                                <label htmlFor="">
                                    <input type="text" placeholder="Your Business Name" onFocus={(e) => e.target.placeholder = ''}
                                        onBlur={(e) => e.target.placeholder = 'Your Business Name'} name="" id="" className="form-control" />
                                </label>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Business Industry</label>
                                <label htmlFor="">
                                    <Dropdown
                                        placeholder='Select'
                                        fluid
                                        selection
                                        options={industryOption}
                                    />
                                </label>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Business Type</label>
                                <label htmlFor="">
                                    <Dropdown
                                        placeholder='Select'
                                        fluid
                                        selection
                                        options={industryOption}
                                    />
                                </label>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">No. of Employees</label>
                                <label htmlFor="">
                                    <Dropdown
                                        placeholder='Select'
                                        fluid
                                        selection
                                        options={employeOption}
                                    />
                                </label>
                            </div>
                            <div className="d-flex">
                                <div className="form-group mr-1">
                                    <label htmlFor="" className="text-center">City/Town</label>
                                    <label htmlFor="">
                                        <input type="text" placeholder="Enter your City/Town" onFocus={(e) => e.target.placeholder = ''}
                                            onBlur={(e) => e.target.placeholder = 'Enter your City/Town'} name="" id="" className="form-control" />
                                    </label>
                                </div>
                                <div className="form-group ml-1">
                                    <label htmlFor="" className="text-center">Country</label>
                                    <label htmlFor="">
                                        <input type="text" placeholder="Enter your Country" onFocus={(e) => e.target.placeholder = ''}
                                            onBlur={(e) => e.target.placeholder = 'Enter your Country'} name="" id="" className="form-control" />
                                    </label>
                                </div>
                            </div>

                            <div className="form-group">
                                <Submit txt="Next" loading={loading} success={success} onClick={handleSubmit} position="justify-content-center" className="dk-submitBtn mt-3" />
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SignupStepTwo;
