import React, { useState } from 'react';
import { Route, Link } from "react-router-dom";
import Footer from "../../elements/footer/index";
import ExtHeader from "../../elements/header/ExtHeader";
import './contact.scss';

const Contact = () => {
    return (
        <React.Fragment>
            <ExtHeader />
            <div className="dk-contactPrivacyMain">
                <h3 className="title">DATA PRIVACY CONTACT</h3>

                <div className="col-12 col-md-10 col-lg-9 m-auto">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-5 pr-0 pl-0">
                            <div className="contact_detail">
                                <h3>DK Global Recruiters</h3>
                                <ul>
                                    <li><i class="fas fa-map-marker-alt"></i> Railway Street, Dublin 1, D01YH07,  Ireland</li>
                                    <li><i class="fas fa-phone-alt"></i> +353 1551 2444</li>
                                    <li><a href="mailto:Info@dkglobalrecruiters.com"><i class="fas fa-envelope"></i> Info@dkglobalrecruiters.com</a></li>
                                    <li><a href="www.dkglobalrecruiters.com" target="_blank"><i class="fas fa-external-link-alt"></i> www.dkglobalrecruiters.com</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-md-7 pr-0 pl-0">
                            <div className="card">
                                <form action="">
                                    <div className="row">
                                        <div className="col-md-12 col-md-9">
                                            <h2>Get In Touch</h2>
                                            <div className="form-group">
                                                <input type="text" className="form-control" placeholder="Your Name" name="" id="" />
                                            </div>
                                            <div className="form-group">
                                                <input type="text" className="form-control" placeholder="Your Email" name="" id="" />
                                            </div>
                                            <div className="form-group">
                                                <input type="text" className="form-control" placeholder="Your Phone" name="" id="" />
                                            </div>
                                            <div className="form-group">
                                                <input type="text" className="form-control" placeholder="Subject" name="" id="" />
                                            </div>
                                            <div className="form-group">
                                                <textarea name="" placeholder="Message" id="" className="form-control"></textarea>
                                            </div>
                                            <p className="d-flex align-items-center">
                                                <input type="checkbox" name="" id="" />
                                                I agree to the <Link to="/terms">Terms of Use</Link> and <Link to="/privacy-policy">Privacy policy</Link>
                                            </p>
                                            <button>Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    );
}

export default Contact;
