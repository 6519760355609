import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './style.scss';

const Home = () => {
    return (
        <React.Fragment>
            <footer>
                <div className="container-fluid">
                    <div className="dk-footerCols">
                        <div className="dk-footerBox" style={{ maxWidth: '380px' }}>
                            <div className="title">DK GLOBAL RECRUITERS</div>
                            <ul>
                                <p className="text-justify">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                            </ul>
                        </div>
                        <div className="dk-footerBox">
                            <div className="title">ABOUT US</div>
                            <ul>
                                <li><Link to="#">Company</Link></li>
                                <li><Link to="#">DK Global</Link></li>
                                <li><Link to="#">Feedback</Link></li>
                                <li><Link to="#">Career</Link></li>
                                <li><Link to="/faq">FAQ</Link></li>
                            </ul>
                        </div>
                        <div className="dk-footerBox">
                            <div className="title">SOLUTIONS</div>
                            <ul>
                                <li><Link to="#">What is ERP</Link></li>
                                <li><Link to="#">Applications</Link></li>
                                <li><Link to="#">Industries</Link></li>
                                <li><Link to="#">Standard</Link></li>
                                <li><Link to="#">Custom</Link></li>
                            </ul>
                        </div>
                        <div className="dk-footerBox">
                            <div className="title">LEGAL</div>
                            <ul>
                                <li><Link to="/terms">Terms of Use</Link></li>
                                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                <li><Link to="/cookies-policy">Cookies Policy</Link></li>
                                <li><Link to="/gdpr-explained">GDPR Explained</Link></li>
                                <li><Link to="/privacy-contact">Contact for Privacy</Link></li>
                            </ul>
                        </div>
                        <div className="dk-footerBox">
                            <div className="title">FOLLOW US</div>
                            <ul>
                                <li><a href="https://www.facebook.com/" target="_blank"><i class="fab fa-facebook-square"></i> Facebook</a></li>
                                <li><a href="https://www.instagram.com/" target="_blank"><i class="fab fa-instagram-square"></i> Instagram</a></li>
                                <li><a href="https://www.linkedin.com/" target="_blank"><i class="fab fa-linkedin"></i> Linkedin</a></li>
                                <li><a href="https://www.youtube.com/" target="_blank"><i class="fab fa-youtube"></i> Youtube</a></li>
                                <li><a href="https://twitter.com/" target="_blank"><i class="fab fa-twitter"></i> Twitter</a></li>
                            </ul>
                        </div>
                    </div>

                    <div className="dk-footerCopyright">
                        <div className="sbtitle text-center">
                            @ 2021 DK Global ERP Limited, All Rights Reserved.
                        </div>
                    </div>
                </div>
            </footer>
        </React.Fragment>
    );
}

export default Home;
