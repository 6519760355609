import React, { useState } from 'react';
import { Route, Link } from "react-router-dom";
import './faq.scss';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));


const FaqContent = () => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <React.Fragment>
            <div className="dk-faqContent">
                <div className={classes.root}>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography className={classes.heading}>What is the location of this company ?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Atque, deserunt fuga? Voluptatibus nesciunt dignissimos consequatur libero at! Aspernatur excepturi dignissimos cupiditate ipsa incidunt deserunt quis itaque a iure, suscipit quia!
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                        >
                            <Typography className={classes.heading}>What is the legal status of the company ?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eius recusandae quas, rerum eos soluta ullam sed quisquam necessitatibus numquam esse incidunt autem optio exercitationem ipsa possimus explicabo. Excepturi, tempora dicta. Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas consectetur architecto maiores, dolorem quos culpa, alias atque iste voluptatem sapiente dicta odio quidem ea doloremque dolore laudantium ab nisi ex!
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3bh-content"
                            id="panel3bh-header"
                        >
                            <Typography className={classes.heading}>Is there any location restriction of using this Platform ?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Iste eum officia libero nemo enim debitis officiis repudiandae alias distinctio, sequi ipsum eligendi perspiciatis explicabo impedit id? Cum error quaerat accusantium.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
        </React.Fragment>
    );
}

export default FaqContent;
