import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from "react-router-dom";
import './style.scss';
import ExtHeader from "../../elements/header/ExtHeader";
import Submit from "../../ui/submitButton";
import { useDispatch, useSelector } from "react-redux";
import { showError } from "../../../slice/utils/message/messageSlice";
import { accountDomain, clearState } from "../../../slice/auth/authSlice";
import { useCookies } from 'react-cookie';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { Dropdown } from 'semantic-ui-react';
import BusinessDetailModal from './BusinessDetailModal';

const Domain = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const inputRef = useRef();

    const { user } = useSelector(state => state.auth)
    const { isSuccess, isError, errorMessage } = useSelector(state => state.auth)

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleSubmit = async () => {
        history.push("/");
    }

    useEffect(() => {
        //document.title = "Login | DK Global CRM"
    }, [])

    useEffect(() => {
        //inputRef.current.focus();
    })
    const UseFocus = () => {
        const htmlElRef = useRef(null);
        const setFocus = () => {
            htmlElRef.current && htmlElRef.current.focus();
        };
        return [htmlElRef, setFocus];
    };

    const [input1Ref, setInput1Focus] = UseFocus();
    useEffect(() => {
        document.title = "Login | DK Global CRM"
        setInput1Focus();
    }, [setInput1Focus])

    // dropdown

    const industryOption = [
        {
            key: 'Select',
            text: 'Select',
            value: 'Select',
        },
    ]

    // 



    return (
        <React.Fragment>
            <ExtHeader />
            <div className="dk-signupMain">
                <div className="d-signUpFlex">
                    <h3 className="title mb-3">ADMIN PERSONAL DETAILS <BusinessDetailModal /></h3>
                    <div className="dk-signupContainer">
                        <form action="">
                            <div className="form-group mb-4">
                                <label htmlFor="">Admin Full Name</label>
                                <input type="text" placeholder="Enter Full Name" onFocus={(e) => e.target.placeholder = ''}
                                    onBlur={(e) => e.target.placeholder = 'Enter Full Name'} name="name" className="form-control col-md-12 m-auto" onClick={() => { setInput1Focus() }} ref={inputRef} />
                            </div>
                            <div className="form-group mb-4">
                                <label htmlFor="">Admin Email Address</label>
                                <input type="text" placeholder="Enter Email Address" onFocus={(e) => e.target.placeholder = ''}
                                    onBlur={(e) => e.target.placeholder = 'Enter Email Address'} name="email" className="form-control col-md-12 m-auto" onClick={() => { setInput1Focus() }} ref={inputRef} />
                            </div>
                            <div className="form-group mb-4">
                                <label htmlFor="">Password</label>
                                <div className="d-flex align-items-center">
                                    <input type="password" className="form-control right-no-radius" name="password" placeholder="Password" onFocus={(e) => e.target.placeholder = ''}
                                        onBlur={(e) => e.target.placeholder = 'Password'} />
                                    <span className="dk-eyeICon domainAppendSpan" style={{ backgroundColor: '#e1e1e4' }}>
                                        <VisibilityOffIcon />
                                    </span>
                                </div>
                            </div>
                            <div className="form-group mb-4">
                                <label htmlFor="">Confirm Password</label>
                                <div className="d-flex align-items-center">
                                    <input type="password" className="form-control right-no-radius" name="password" placeholder="Confirm Password" onFocus={(e) => e.target.placeholder = ''}
                                        onBlur={(e) => e.target.placeholder = 'Confirm Password'} />
                                    <span className="dk-eyeICon domainAppendSpan" style={{ backgroundColor: '#e1e1e4' }}>
                                        <VisibilityOffIcon />
                                    </span>
                                </div>
                            </div>
                            <div className="form-group mb-4">
                                <label htmlFor="">How did you hear about us ?</label>
                                <label htmlFor="">
                                    <Dropdown
                                        placeholder='Select'
                                        fluid
                                        selection
                                        options={industryOption}
                                    />
                                </label>
                                <label htmlFor="" className="dk-agreeTerms d-flex align-items-center justify-content-center">
                                    <input type="checkbox" name="" id="" />
                                    <span style={{ fontSize: '12px' }}>I agree with all <Link to="#" style={{ color: '#2d96b6' }}>Terms & Conditions</Link> to create account.</span>
                                </label>
                            </div>
                            <div className="form-group dk-removeIconBtn">
                                <Submit txt="Submit" loading={loading} success={success} onClick={handleSubmit} position="justify-content-center" className="dk-submitBtn mt-0" />
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Domain;
