import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './style.scss';
import Modal from 'react-bootstrap/Modal';

const SendQueryModal = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <React.Fragment>
            <Link to="#" className="mt-2" onClick={handleShow}>Send Query</Link>

            <Modal className="dk-sendQueryMain" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>SENT QUERY</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="dk-sendQuery-body">
                        <form action="">
                            <div className="form-group">
                                <label htmlFor="">Name</label>
                                <label htmlFor="">
                                    <input type="text" placeholder="Your Full Name" name="" id="" className="form-control" />
                                </label>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Email Address</label>
                                <label htmlFor="">
                                    <input type="text" placeholder="Your Email Address" name="" id="" className="form-control" />
                                </label>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Phone</label>
                                <label htmlFor="">
                                    <input type="text" placeholder="Your Phone Number" name="" id="" className="form-control" />
                                </label>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Massage</label>
                                <label htmlFor="">
                                    <textarea name="" id="" placeholder="Write Message Here" className="form-control"></textarea>
                                </label>
                            </div>
                            <div className="form-group mb-0 text-center">
                                <button className="dk-submitBtn">Submit</button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}
export default SendQueryModal;
