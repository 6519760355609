import React, { useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Switch, Route } from "react-router-dom";
import Home from './components/pages/home';
import Login from './components/pages/login/index';
import Domain from './components/pages/login/Domain';
import Account from './components/pages/login/Login';

import Signup from './components/pages/signup';
import SignupDomain from './components/pages/signup/domain';
import CreateExperience from './components/pages/signup/CreateExperience';
import SignupStepTwo from './components/pages/signup/SignupStepTwo';
import FaqPage from './components/pages/faq/FaqPage';
import Terms from './components/pages/terms/Terms';
import PrivacyPolicy from './components/pages/privacypolicy/PrivacyPolicy';
import GdprExplained from './components/pages/gdprexplained/GdprExplained';
import Contact from './components/pages/contact/Contact';
import CookiesPolicy from './components/pages/cookiespolicy/CookiesPolicy';

function App() {
  return (
    <React.Fragment>
      <div className="App">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/faq" component={FaqPage} />
          <Route path="/terms" component={Terms} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/gdpr-explained" component={GdprExplained} />
          <Route path="/cookies-policy" component={CookiesPolicy} />
          <Route path="/privacy-contact" component={Contact} />
          <Route path="/login/domain" component={Domain} />
          <Route path="/login/account" component={Account} />
          <Route path="/login" component={Login} />

          <Route path="/signup/domain" component={SignupDomain} />
          <Route path="/signup/create-experience" component={CreateExperience} />
          <Route path="/signup/sign-steptwo" component={SignupStepTwo} />
          <Route path="/signup" component={Signup} />


        </Switch>
      </div>
    </React.Fragment>
  );
}

export default App;
